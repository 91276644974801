import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Script from 'next/script';
import { useRouter } from 'next/router';
import 'mapbox-gl/dist/mapbox-gl.css';
import { GA_TRACKING_ID, pageView } from '../lib/gtag';
import '../styles/globals.css';
import '../styles/colors.css';

export default function MyApp({ Component, pageProps }) {
  const router = useRouter();

  useEffect(() => {
    const handleRouteChange = (url) => {
      pageView(url);
    };

    router.events.on('routeChangeComplete', handleRouteChange);
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events]);

  const getLayout = Component.getLayout || ((page) => page);

  return getLayout(
    <>
      <Script
        strategy="afterInteractive"
        src={`https://www.googletagmanager.com/gtag/js?id=${GA_TRACKING_ID}`}
      />
      <Script
        id="gtag-init"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${GA_TRACKING_ID}', {
              page_path: window.location.pathname,
            });
          `,
        }}
      />
      <Component {...pageProps} />
    </>,
  );
}

MyApp.propTypes = {
  Component: PropTypes.oneOfType([PropTypes.element, PropTypes.func]),
  pageProps: PropTypes.object, // eslint-disable-line
};

MyApp.defaultProps = {
  Component: () => {},
  pageProps: null,
};
